module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Blumenhaus Georg","short_name":"BlumenGeorg","description":"Blumenladen Georg Koeln, Flower shop Georg in Cologne","start_url":"/","background_color":"#663399","theme_color":"#121212","display":"minimal-ui","icon":"src/images/logo-lg.png","icons":[{"src":"icons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"icons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"icons/apple-icon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"icons/apple-icon-60x60.png","sizes":"60x60","type":"image/png"},{"src":"icons/apple-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"icons/apple-icon-76x76.png","sizes":"76x76","type":"image/png"},{"src":"icons/apple-icon-114x114.png","sizes":"114x114","type":"image/png"},{"src":"icons/apple-icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"icons/apple-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"icons/mstile-150x150.png","sizes":"150x150","type":"image/png"},{"src":"icons/apple-icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"icons/apple-icon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"icons/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"icons/apple-icon-precomposed.png","sizes":"192x192","type":"image/png"},{"src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"icons/iTunesArtwork@1x.png","sizes":"512x512","type":"image/png"},{"src":"icons/iTunesArtwork@2x.png","sizes":"1024x1024","type":"image/png"},{"src":"icons/iTunesArtwork@3x.png","sizes":"1536x1536","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3c2d84e1d2fc92822cbc6e3c929d0dd2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-125181627-1","GTM-P6H9G7V"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
